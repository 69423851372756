<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <!-- <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="折扣名称">
              <a-input v-model="searchData.rebateName" placeholder="折扣名称"></a-input>
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="大区名称">
              <a-input v-model="searchData.areaName" placeholder="大区名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="事务所名称">
              <a-input v-model="searchData.firmName" placeholder="事务所名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商名称">
              <a-input v-model="searchData.dealerName" placeholder="经销商名称"></a-input>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="折扣时间">
              <DateRange
                :startTime.sync="searchData.startTimeString"
                :endTime.sync="searchData.endTimeString"
              ></DateRange>
            </a-form-model-item>
          </a-col> -->
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <!-- <div>
            <a-button type="primary" @click="toHandler('add')">新增</a-button>
            <a-button type="primary" @click="toHandler('edit')">修改</a-button>
            <a-button type="primary" @click="toHandler('del')">删除</a-button>
        </div> -->
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.areaId"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :rowClassName="rowClassName"
      >
      <span slot="rebateTotal" slot-scope="text"> {{text | formatMoney}} </span>
      <span slot="usedRebateTotal" slot-scope="text"> {{text | formatMoney}} </span>
      <span slot="canUsedRebate" slot-scope="text"> {{text | formatMoney}} </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerRebate-edit-modal ref="DealerRebateEditModal" @reload="getData"></DealerRebate-edit-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/rebate_colums.js'
import DealerRebateEditModal from './components/DealerRebateEditModal.vue'
import { delDealerRebate, listDealerRebate } from './api/DealerRebateTotalApi'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerRebate',
  components: {
    DealerRebateEditModal,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listDealerRebate({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      this.page.pageSize = 10
      this.getData()
    },
    // 修改状态
    onChangeStatus(record) {
      // record.flagEnable = !record.flagEnable
      // changRoleStatus(pick(record, 'id')).then(res => {
      //     if(res.code == 200) {
      //     this.$message.success(res.message)
      //     this.handleOk()
      //     }
      // })
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1;
      this.page.pageSize = 10
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerRebateEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerRebateEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              console.log(_this.selectedRows[0])
              const res = await delDealerRebate(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
